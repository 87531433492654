export const NAVBAR_LINKS = [
  {
    name: 'About',
    link: 'mission',
  },
  {
    name: 'Whitepaper',
    link: 'whitepaper',
  },
  {
    name: 'Contact',
    link: 'contact',
  },
  {
    name: '|',
    link: '',
  },
  {
    name: 'Data',
    link: 'data',
  },
  {
    name: 'Swap',
    link: 'swap',
  },
];
